@import 'styles/bootstrap-overrides/_colors';

ng-select {
    width: 100%;
}

.ng-select-container {
    border-radius: 2px !important;
}

.ng-placeholder {
    padding-top: 2px;
    color: $eden !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {

    .ng-option.ng-option-selected {
        color: $eden !important;
        background-color: rgba($patina, 0.1) !important;
    }

    .ng-option.ng-option-marked {
        color: $white !important;
        background-color: $patina !important;
    }

    .ng-optgroup.ng-option-marked {
        background-color: $patina !important;
    }

}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    color: $eden !important;
    background-color: rgba($patina, 0.1) !important;
    border: 1px solid rgba($patina, 0.2) !important;
}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon.left {
    border-right: 1px solid rgba($patina, 0.5) !important;

    &:hover {
        background-color: rgba($patina, 0.2) !important;
    }
}

.ng-select {
    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            border-color: $eden !important;
            box-shadow: none !important;
        }

        &.ng-select-opened > .ng-select-container {
            border-color: $eden !important;
            box-shadow: none !important;
        }
    }
}
