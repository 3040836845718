$gutter: 15px;

.bo-container {
    margin: 25px;
}

.filter-controls-container {
    display: flex;
    align-items: flex-end;
    margin: 20px #{-$gutter};

    .search-actions {
        display: flex;
        flex-direction: column-reverse;
        width: calc(15% - #{$gutter});

        .search-button {
            margin: 0 $gutter;
            height: 35px;
            line-height: 35px;
            padding: 0;
        }

        .search-button:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    .filters-container.two-lines {
        flex-wrap: wrap;
        justify-content: unset;
    }

    .filters-container {
        display: flex;
        width: 100%;

        abstract-filter {
            flex: 1;
        }

        .ng-select-container {
            height: 35px !important;
        }

        ame-type-license-filter {
            display: flex;
            justify-content: space-between;

            .tech-type-filter,
            .tech-level-filter {
                width: calc(50% - #{$gutter});
            }
        }

        .term-filter,
        .group-filter-container,
        .date-filter-container,
        .aircrafts-filter-container,
        .skills-filter-container,
        .user-status-filter,
        .ameTypeLicense-filter {
            margin: 0 10px;
        }

        .user-status-filter {
            margin-bottom: 10px;
        }

        .search-actions {
            margin-right: 10px;
        }
    }
}

.sorted {
    cursor: pointer;
}

.pagination-control {
    display: flex;
    justify-content: center;
}

.sorting-arrow {
    opacity: 0;
    vertical-align: middle;

    &.asc {
        opacity: 1;
        transform: rotateZ(180deg);
    }

    &.desc {
        opacity: 1;
    }
}
