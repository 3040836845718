.table {
    thead {
        tr {
            th {
                &.clickable {
                    cursor: pointer;
                }

                i {
                    vertical-align: middle;
                    line-height: 20px;
                    opacity: 0;

                    &.active {
                        opacity: 1;
                    }

                    &.asc {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    span hr {
        margin: 1px 0;
    }
}
