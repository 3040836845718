.manage-wrapper-container {

    tab-navigation {
        display: block;
        margin-bottom: 15px;
    }

    .action-buttons {
        general-button {
            margin-right: 10px;
        }
    }

    .main {
        margin-top: 30px;

        h2 {
            margin-bottom: 35px;
            color: $black;
        }
    }

    .pagination {
        justify-self: center;
    }
}

.group-registration-wrapper {
    .title {
        margin-bottom: 20px;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
    }

    form {
        > div {
            margin-bottom: 20px;
        }
    }
}

.custom-control-label {
    user-select: none !important;
}

.backoffice-facility {
    .modal-content {
        padding: 0;
    }
    .facility-profile-container {
        padding: 0;
        height: unset;
    }
}
