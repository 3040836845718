main {
    min-height: 100vh;
    padding-bottom: 1px;
    background-color: $gallery;

    &.logged-in {
        padding-top: 64px;
    }
}

.cover-full-height {
    min-height: 100vh;
}

.compontent-name {
    font-size: 20px;
    font-weight: $font-weight-bold;
    color: $pitch-black;
    margin-bottom: 10px;
}

.bo-container {
    padding: 20px;
    background-color: $white;
    box-shadow: 1px 1px 5px 0 rgba($tundora, 0.4);
}

.pointer {
    cursor: pointer;
}

.staffnow-header {
    background-color: $lightGrey !important;
    color: $pitch-black !important;
    box-shadow: 0 0 15px rgba($tundora, 0.4);

    .aside-left {
        //hamburger menu icon
        i {
            display: block !important;
        }
    }

    .staffnow-logo-container {
        width: 100px;
        #logo {
            fill: black !important;
        }
    }

    .dropdown {
        background-color: $white !important;
        &::after {
            border-bottom-color: $white !important;
        }
    }
}

.navigation-mobile {
    width: 20% !important;
}

main {
    transition: width, margin-left 0.3s ease-in-out;
}

main.menu-opened {
    width: 80%;
    margin-left: 20%;
    animation-name: main-content;
    animation-duration: 0.3s;
}

@keyframes main-content {
    from {
        width: 100%;
        margin-left: 0;
    }
    to {
        width: 80%;
        margin-left: 20%;
    }
}

@keyframes menu {
    from {
        left: -20%;
    }
    to {
        left: 0;
    }
}

.splash-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $patina;
    font-size: 22px;
}

staff-loader {
    .splash-screen-loader {
        .dot {
            color: $patina !important;
            width: 20px !important;
            height: 20px !important;
        }
    }
}

$t: 1.5s;
.staff-loader {
    text-align: center;
    margin: auto;
    .dot {
        color: $white;
        display: inline-block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        &:after {
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: animated $t
                calc(((var(--i) + var(--o, 0)) / var(--n) - 1) * #{$t}) infinite;
        }

        &:after {
            --o: 1;
            background: currentcolor;
            content: '';
        }
    }
}

.purple-loader {
    .dot {
        color: $patina !important;
        width: 20px !important;
        height: 20px !important;
    }
}

@keyframes animated {
    0%,
    50% {
        transform: scale(0);
    }
}

//Default styles for a loader in a button
staff-loader {
    .btn-loader {
        .dot {
            color: $white !important;
            width: 10px !important;
            height: 10px !important;
        }
    }

    .disabled-btn-loader {
        .dot {
            color: $eden !important;
            width: 10px !important;
            height: 10px !important;
        }
    }
}

//Default styles for a loading button
.loading-btn {
    &.loading {
        pointer-events: none;
        staff-loader {
            display: block !important;
        }
        .btn-text {
            display: none;
        }
    }
    staff-loader {
        display: none;
    }
}

.default-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: contain;
    color: white;
    background-color: $patina;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        font-size: 29px;
    }
}

.notifications-badge {
    position: relative;
}

.ACTIVE {
    color: $green;
}

.PENDING {
    color: $orange;
}

.IN_REVIEW {
    color: $orange;
}

.INCOMPLETE {
    color: $crimson;
}

.DEACTIVATED,
.DELETED {
    color: $crimson;
}

.input-disabled {
    pointer-events: none;
}

@include media-breakpoint-down(xs) {
    .notifications-opened {
        display: none;
    }
}

.facility-reg-form-container,
.user-reg-form-container {
    display: grid;
    justify-content: center;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 15px;
    margin: 0 auto;
    padding: 25px 0;
    max-width: 300px;

    input.ngrx-forms-invalid.ngrx-forms-touched {
        border: 0.5px solid $signal-red;
    }

    select.ngrx-forms-invalid.ngrx-forms-touched {
        border: 0.5px solid $signal-red;
    }

    .h2 {
        width: 300px;
        text-align: center;
    }

    .form-control {
        margin-bottom: 15px;
    }

    .custom-select {
        margin-bottom: 15px;
    }

    .action-buttons {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        margin-top: 15px;
    }
}

.no-cover {
    display: grid;
    align-items: center;
    min-height: 300px;
    height: 100%;
    background: $eden;
    user-select: none;
    justify-content: center;

    .material-icons {
        color: $white;
        font-size: 80px;
    }
}

.facility-logo-placeholder {
    text-align: center;
    display: grid;
    padding: 5px;
    width: 100px;
    height: 100px;
    background-color: $gray-dark;
    color: $patina;
    font-weight: bold;
    .logo-text {
        line-height: 21px;
        font-size: 21px;
    }
    .company-text {
        color: $white;
        line-height: 11px;
        font-size: 11px;
    }
}

.facility-profile-modal {
    width: unset;
    .modal-content {
        padding: 0;
    }
}

.date-picker {
    padding-right: 30px !important;
    background-image: url('../assets/images/calendar-icon.svg');
    background-repeat: no-repeat;
    background-position: 96%;
    cursor: pointer;

    &.disabled {
        color: $gray-dark;
        background-color: $lightGrey;
        opacity: 1;
    }
}

.modal-center {
    top: 50%;
    transform: translateY(-50%) !important;
}

.modal-center-horizontal {
    margin: auto;
}

.modal-big {
    max-width: 1000px;
}

.modal-range-picker .modal-content {
    height: 110% !important;
}

.validation-error {
    display: inline-block;
    width: 100%;
    color: $crimson;
    font-size: 12px;
}
