//Here import the component specific styles
@import './manage-technicians.component';
@import './ngx-calendar-custom-theme.scss';
@import './ng-custom-multiselect.scss';
@import '../../../../libs/notifications/src/lib/components/notification-list/notification-list.component';
@import '../app/modules/users/components/manage-wrapper/manage-wrapper.component';
@import '../app/modules/users/components/table-listing/table-listing.component';
@import '../app/modules/manage-content/manage-content.component.scss';


@media (min-width: 576px) {
    .modal-dialog {
        max-width: 576px;
    }
}

.agencies-list-modal {
    max-width: 738px;

    @media (max-height: 840px) {
        max-height: 100vh;
        margin: 0 auto;

        .modal-content {
            height: 100%;
        }

        .agencies-list-container {
            height: calc(100vh - 175px) !important;
        }
    }
}

.agencies-list-modal,
.manage-package-offer-files-modal,
.package-offer-application-modal {
    position: relative;

    .modal-title {
        text-align: center;
        font-size: 26px;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
    }

    .confirm-btn {
        margin: 20px auto;
    }
}
